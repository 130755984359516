import * as lodash from 'lodash';

export interface CurrencyFormatterOps {
    positivePrefix?: boolean;
    coinSymbol?: boolean;
}

export const currencyFormatDE = (num: number, opts: CurrencyFormatterOps = {
    positivePrefix: false,
    coinSymbol: true
}) => {
    if (!lodash.isNil(num)) {
        const originalNum = lodash.cloneDeep(num);
        let result = lodash.cloneDeep(num)
            .toFixed(2)
            .replace('.', ',')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + (opts && opts.coinSymbol ? ' €' : '');
        if (opts && opts.positivePrefix && originalNum > 0) {
            result = `+${result}`;
        }
        return result;
    }
    return null;
};
